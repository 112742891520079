// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
// require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("admin-lte/plugins/bootstrap/js/bootstrap.bundle")
require("admin-lte/dist/js/adminlte")

import $ from 'jquery';
global.$ = jQuery;

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "@fortawesome/fontawesome-free/css/all.min";
import "admin-lte/dist/css/adminlte.min";

// import '../stylesheets/application'; // This file will contain your custom CSS
import 'packs/application.css';
import "admin-lte/plugins/select2/css/select2.min";
import "admin-lte/plugins/select2-bootstrap4-theme/select2-bootstrap4.min.css";
import "admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min";
import "admin-lte/plugins/bootstrap-switch/css/bootstrap3/bootstrap-switch.min.css";
import "flatpickr/dist/flatpickr.min.css";
import "admin-lte/plugins/chart.js/Chart.min.css"

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)